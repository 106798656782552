import { useTranslation } from 'react-i18next'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import { AppBox } from './AppBox'

export const ShowError = ({ errorText }) => {
  const { t } = useTranslation()

  return (
    <AppBox>
      <div className='title-wrapper'>
        <h1>
          {t('error_header', 'Something Went Wrong')}
        </h1>
        <p className='sub-title'>
          {errorText || t('unexpected_error', 'It looks like something went wrong, sorry about that. Please try again later or contact Customer Support.')}
        </p>
      </div>
      <BasicButton type={'link'} href={'https://support.pokemon.com'} text={t('customer_support', 'Contact Customer Support')} />
    </AppBox>
  )
}

export default ShowError
