import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import './i18n'
import App from './App'
import config from './config'
import reportWebVitals from './reportWebVitals'
import { LoadingProvider } from '@pokemon/design.ui.loading-spinner'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <LoadingProvider >
        <App />
      </LoadingProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

datadogRum.init({
  applicationId: 'd3489cb3-7eb6-4d35-9980-6b3d602dc8a9',
  clientToken: 'pub549c790bfd95e830ce8dd5ab0f7f3fff',
  site: 'datadoghq.com',
  service: 'account-deletion-frontend',
  env: process.env.REACT_APP_STAGE,
  version: require('../package.json').version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    config.ADAPI_BASE_URL
    // will add URL once Oryhydra and UPAPI is integration with DataDog
    // `https://access.${process.env.REACT_APP_STAGE}.pokemon.com`,
    // (url) => url.startsWith(`https://me.${process.env.REACT_APP_STAGE}.pokemon.com`),
  ]
})

datadogRum.startSessionReplayRecording()

datadogLogs.init({
  clientToken: 'pub549c790bfd95e830ce8dd5ab0f7f3fff',
  site: 'datadoghq.com',
  silentMultipleInit: true,
  forwardErrorsToLogs: true,
  sampleRate: 100
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
