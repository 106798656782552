import { useTranslation } from 'react-i18next'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import { AppBox } from './AppBox'

export const SuccessfulDelete = ({ childguid }) => {
  const { t } = useTranslation()
  const successMessage = childguid ? t('success_subtitle_child', 'Your child’s account has been queued for deletion and will be deleted soon.') : t('success_subtitle', 'Your account has been queued for deletion and will be deleted soon.')

  return (
    <AppBox>
      <div className='title-wrapper'>
        <h1>
          {t('success_header', 'Success!')}
        </h1>
        <p className='sub-title'>
          {successMessage}
        </p>
      </div>
      <BasicButton type="link" text={t('return_button', 'Return to Pokémon.com')} href="https://www.pokemon.com"/>
    </AppBox>
  )
}
