import { useContext, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { AppContext } from './App'
import { fetchAccessTokenFromCallback } from './OAuthUtil'
import { useTranslation } from 'react-i18next'
import { Spinner } from '@pokemon/design.ui.loading-spinner'
import { AppBox } from './AppBox'

export const Callback = () => {
  const location = useLocation()
  const history = useHistory()
  const appContext = useContext(AppContext)
  const { t } = useTranslation()

  const fetchAccessToken = async () => {
    const queryString = new URLSearchParams(location.search)
    const accessToken = await fetchAccessTokenFromCallback(queryString)
    window.sessionStorage.setItem('oauth_access_token', accessToken)
    history.replace('/confirm')
  }

  const needAccessToken = useRef(true)
  useEffect(() => {
    if (needAccessToken.current) {
      appContext.handlePromise(fetchAccessToken())
      needAccessToken.current = false
    }
  })

  // This component will always redirect to another page
  return (
    <AppBox>
      <Spinner altText={t('pokemon_spinner', 'Loading')} />
    </AppBox>
  )
}

export default Callback
