import i18n from 'i18next'
import { useTranslation, Trans } from 'react-i18next'
import { BulletBox, BulletBoxItem } from './BulletBox'
import DeletedAppLogos from './assets/images/deleted_app_logos.png'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import { Form } from 'react-final-form'
import { Link, useHistory } from 'react-router-dom'
import { confirmDelete as confirmDeleteRequest, confirmDeleteChild as confirmDeleteChildRequest } from './requests'
import { useContext, useEffect } from 'react'
import { AppContext } from './App'
import { localizeLink } from '@tpci/i18next-ext'
import config from './config'
import { ConsentedClient } from './ConsentedClient'
import { DeleteHeader } from './DeleteHeader'
import { LoadingContext } from '@pokemon/design.ui.loading-spinner'
import { AppBox } from './AppBox'

export const ConfirmDelete = ({ clients, loadClients, childguid }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { handleError } = useContext(AppContext)
  const { setLoading } = useContext(LoadingContext)
  const deleteWarning = childguid ? t('confirm_account_delete_warning_child', 'Your child will permanently lose access to the following:') : t('confirm_account_delete_warning', 'You will permanently lose access to the following:')
  const deletedItemAllApplicationsMsg = childguid ? t('deleted_item_all_applications_child', 'All Pokémon applications that your child has logged-in to with their Pokémon Trainer Club account') : t('deleted_item_all_applications', 'All Pokémon applications that you have logged-in to with your Pokémon Trainer Club account')
  const deleteAccountNoReactivateWarning = childguid ? t('delete_account_no_reactivate_warning_child', 'Pokémon will not be able to reactivate your child’s account.') : t('delete_account_no_reactivate_warning', 'Pokémon will not be able to reactivate your account.')
  const deleteAccountRevokePermissionRecommendation = childguid
    ? <Trans
      t={t}
      i18nKey="delete_account_revoke_permission_recommendation_child"
      defaults="If you just want to revoke access for a given application, you can <delete_account_revoke_permission_link>edit their permissions settings</delete_account_revoke_permission_link> instead of deleting the account"
      components={{
        delete_account_revoke_permission_link: <a href={localizeLink(i18n.language, config.AUTHORIZED_APPS_URL)}></a>
      }}
    />
    : <Trans
      t={t}
      i18nKey="delete_account_revoke_permission_recommendation"
      defaults="If you just want to revoke access for a given application, you can <delete_account_revoke_permission_link>edit your permissions settings</delete_account_revoke_permission_link> instead of deleting the account"
      components={{
        delete_account_revoke_permission_link: <a href={localizeLink(i18n.language, config.AUTHORIZED_APPS_URL)}></a>
      }}
    />
  const emailSubscriptions = childguid ? t('email_subs_child', 'Email subscriptions tied to their Pokémon Trainer Club account') : t('email_subs', 'Email subscriptions tied to your Pokémon Trainer Club account')

  // ensure the user has come to this page after a login. Run only once, on pageload.
  useEffect(() => {
    if (!window.sessionStorage.getItem('oauth_access_token')) {
      handleError(new Error('Somehow user reached /confirm without an access token'))
    }
    if (!window.sessionStorage.getItem('given_otc')) {
      handleError(new Error('Somehow user reached /confirm without a one time code'))
    }
  }, [handleError])

  useEffect(() => {
    if (config.ACCOUNT_DELETE_GET_CONSENTED_APPS && !clients) {
      loadClients()
    }
  }, [clients, loadClients, setLoading])

  return (
    <Form
      onSubmit={() => handleDelete(t, handleError, setLoading, history, childguid)}
      render={
        ({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <AppBox>
              <DeleteHeader childguid={childguid} />
              <p style={{ marginBottom: '5px' }}>
                {deleteWarning}
              </p>
              <BulletBox>
                <BulletBoxItem>
                  {t('deleted_item_account_details', 'Account details')}
                </BulletBoxItem>
                <ShowClients clients={clients} deletedItemAllApplicationsMsg={deletedItemAllApplicationsMsg} />
                <BulletBoxItem>
                  {t('deleted_item_organized_play', 'Play! Pokémon participation & records')}
                </BulletBoxItem>
                <BulletBoxItem>
                  {emailSubscriptions}
                </BulletBoxItem>
              </BulletBox>
              <p>
                {deleteAccountRevokePermissionRecommendation}
              </p>
              <strong style={{ marginTop: '15px' }}>
                {deleteAccountNoReactivateWarning}
              </strong>
              <span style={{ display: 'flex', width: '100%' }}>
                <BasicButton
                  type='link'
                  href='https://www.pokemon.com'
                  style={{ marginRight: '15px' }}
                  text={t('delete_account_cancel', 'No, Take Me Back')}
                  buttonStatus={'secondary'}
                />
                <BasicButton
                  type={'submit'}
                  text={t('delete_account_confirm', 'Delete')}
                  buttonStatus={'negative'}
                  disabled={submitting}
                />
              </span>
            </AppBox>
          </form>
        )
      }
    />
  )
}

const ShowClients = ({ clients, deletedItemAllApplicationsMsg }) => {
  const { t } = useTranslation()

  if (!config.ACCOUNT_DELETE_GET_CONSENTED_APPS) {
    return <StaticClients deletedItemAllApplicationsMsg={deletedItemAllApplicationsMsg}/>
  }

  if (!clients || clients.length === 0) {
    return null
  }

  const shownClients = clients.slice(0, 2)
  const numMoreClients = clients.length - shownClients.length
  return (
    <BulletBoxItem>
      {deletedItemAllApplicationsMsg}
      {shownClients.map((client) =>
        <ConsentedClient key={client.client_name} client={client} style={{ marginTop: '15px' }}/>
      )}
      {numMoreClients > 0 &&
        <div style={{ marginTop: '15px' }}>
          <Link to={{ pathname: '/all-apps' }}>{t('see_more_apps', 'See {{count}} more application(s)', { count: numMoreClients })}</Link>
        </div>
      }
    </BulletBoxItem>
  )
}

const StaticClients = ({ deletedItemAllApplicationsMsg }) => {
  const { t } = useTranslation()
  return (
    <BulletBoxItem>
      {deletedItemAllApplicationsMsg}
      <img alt={t('deleted_item_all_application_alt', 'Including Pokémon Unite, Pokémon GO, Pokémon TCG Live, Pokémon TV, and others.')} src={DeletedAppLogos} />
    </BulletBoxItem>
  )
}

const handleDelete = async (t, handleError, setLoading, history, childguid) => {
  try {
    setLoading(true)
    if (childguid) {
      await confirmDeleteChildRequest(window.sessionStorage.getItem('oauth_access_token'), window.sessionStorage.getItem('given_otc'), childguid)
    } else {
      await confirmDeleteRequest(window.sessionStorage.getItem('oauth_access_token'), window.sessionStorage.getItem('given_otc'))
    }
    setLoading(false)
    history.replace('success')
  } catch (error) {
    switch (error.response?.status) {
      case 400:
      case 403: // this could be an error, but it could also just be normal expiry. Passing it along just in case.
        handleError(error, t('deletion_error_potentially_expired', 'We could not authenticate your request to delete your account. Your authentication may have expired. Either try again, or contact customer service.'))
        break
      case 409: // account is blocked. not really an error.
        handleError(null, t('deletion_error_blocked', 'Your account cannot be deleted at this time.'))
        break
      default:
        handleError(error)
        break
    }
  }
}
