import Axios from 'axios'
import config from './config'

const getRequestConfig = (accessToken) => ({
  headers: { Authorization: `Bearer ${accessToken}` },
  validateStatus: code => code === 200
})

export const confirmDelete = (accessToken, oneTimeCode) => {
  const body = {
    code: oneTimeCode
  }

  return Axios.post(`${config.ADAPI_BASE_URL}/confirm-delete`, body, getRequestConfig(accessToken))
}

export const confirmDeleteChild = (accessToken, oneTimeCode, childguid) => {
  const body = {
    code: oneTimeCode
  }

  return Axios.post(`${config.ADAPI_BASE_URL}/confirm-delete/${childguid}`, body, getRequestConfig(accessToken))
}

export const getConsentedClients = async (accessToken) => {
  const response = await Axios.get(`${config.TOKEN_BASE_URL}/consented-clients`, getRequestConfig(accessToken))
  return response.data
}

export const getConsentedClientsChild = (accessToken, childguid) => {
  return Axios.get(`${config.TOKEN_BASE_URL}/consented-clients/${childguid}`, getRequestConfig(accessToken))
}
