/* eslint jsx-a11y/label-has-associated-control: 1 */
import { useTranslation, Trans } from 'react-i18next'
import React from 'react'

function Footer () {
  const { t } = useTranslation()
  const currYear = new Date().getFullYear()

  return (
    <div>
      <footer className='footer-wrapper'>
        <span className='copyright'>
          <span style={{ display: 'block', marginBottom: '10px' }}>
            <Trans
              t={t}
              i18nKey='copyright'
              defaults='©{{curr_year}} Pokémon.<br/>©1995 - {{curr_year}} Nintendo/Creatures Inc./GAME FREAK inc. TM, ®Nintendo.'
              values={{ curr_year: currYear }}
            />
          </span>
        </span>
      </footer>
    </div>
  )
}

export { Footer }
