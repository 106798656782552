import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Footer } from './template'
import { ContentBox } from '@pokemon/design.ui.containers.content-box'
import { Mascots } from '@pokemon/design.ui.containers.background'
import { LoadingContainer } from '@pokemon/design.ui.loading-spinner'

export const AppBox = ({ showBackButton, children }) => {
  const { t } = useTranslation()

  const history = useHistory()
  const goBack = () => history.goBack()

  return (
    <>
      <ContentBox onBack={showBackButton ? goBack : undefined} backButtonAltText={t('back', 'Back')}>
        <LoadingContainer altText='_'>
          <div className='App'>
            <div className='body-container'>
              <div className='body-wrapper'>
                {children}
              </div>
            </div>
          </div>
          <Footer />
        </LoadingContainer>
      </ContentBox>
      <Mascots />
    </>
  )
}
