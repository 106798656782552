import './bullet_box.css'

export const BulletBox = ({ children, className }) => (
  <ul className={`bullet-box ${className || ''}`}>
    {children}
  </ul>
)

export const BulletBoxItem = ({ children, className, disableBullet, ...props }) => {
  let childrenArray
  if (!Array.isArray(children)) {
    childrenArray = [children]
  } else {
    childrenArray = children
  }

  return (
    <li className={`bullet-item ${disableBullet ? 'disable-bullet' : ''} ${className || ''}`}>
      {childrenArray.map((child, index) => {
        if (index === 0) {
          child = <span className="prefix-bullet" key={index} {...props}>{child}</span>
        }
        return child
      })}
    </li>
  )
}
