import { countryCodeReplacementString } from '@tpci/i18next-ext'

const overridesByStage = {
  dev: {
    OAUTH_CALLBACK_URL: 'http://localhost:3000/callback'
  }
}

export function buildConfig (stage, domain) {
  return {
    // Add common config values here
    OAUTH_CLIENT_ID: 'account-deletion-frontend',
    OAUTH_SCOPES: 'openid delete_account list_clients',
    OAUTH_PROVIDER_URL: `https://access.${domain}`,
    OAUTH_LOGIN_URL: `https://access.${domain}/login`,
    OAUTH_CALLBACK_URL: `https://delete.${domain}/callback`,
    ADAPI_BASE_URL: `https://api.delete.${domain}`,
    TOKEN_BASE_URL: `https://me.${domain}/me`,
    AUTHORIZED_APPS_URL: `https://www.${domain}/${countryCodeReplacementString}/pokemon-trainer-club/authorized-apps`,
    ACCOUNT_DELETE_GET_CONSENTED_APPS: true,
    ...(overridesByStage[stage] ?? {})
  }
}

export default buildConfig(process.env.REACT_APP_STAGE, process.env.REACT_APP_DOMAIN)
