import { useTranslation } from 'react-i18next'
import { BulletBox, BulletBoxItem } from './BulletBox'
import { ConsentedClient } from './ConsentedClient'
import { DeleteHeader } from './DeleteHeader'
import { useEffect } from 'react'
import { AppBox } from './AppBox'

export const AllApps = ({ clients, loadClients, childguid }) => {
  const { t } = useTranslation()
  const allApplicationsWarningMessage = childguid ? t('show_all_applications_warning_child', 'When you delete your child’s account they will lose access to the following Pokémon applications that they have logged-in to with their Pokémon Trainer Club account:') : t('show_all_applications_warning', 'When you delete your account you will lose access to the following Pokémon applications that you have logged-in to with your Pokémon Trainer Club account:')

  useEffect(() => {
    if (!clients) {
      loadClients()
    }
  }, [clients, loadClients])

  return (
    <AppBox showBackButton={true}>
      <DeleteHeader childguid={childguid} />
      <p style={{ marginBottom: '5px' }}>
        {allApplicationsWarningMessage}
      </p>
      { clients
        ? <BulletBox>
            {clients.map((client) => (
              <BulletBoxItem key={client.client_name} disableBullet={true}>
                <ConsentedClient client={client}/>
              </BulletBoxItem>
            ))}
          </BulletBox>
        : null
      }
    </AppBox>
  )
}

export default AllApps
