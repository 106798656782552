import { useTranslation } from 'react-i18next'

export const DeleteHeader = ({ childguid }) => {
  const { t } = useTranslation()
  const headerText = childguid ? t('confirm_page_header_child', 'Delete Your Child’s Pokémon Trainer Club Account') : t('confirm_page_header', 'Delete Your Pokémon Trainer Club Account')

  return (
    <h1 style={{ marginBottom: '20px' }}>
      {headerText}
    </h1>
  )
}
