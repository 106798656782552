import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { defaultI18NextOptions, syncDocumentTranslations } from '@tpci/i18next-ext'

// keep title in sync with index.html
syncDocumentTranslations(i18n, () => {
  return i18n.t('document_title', 'The Official Pokémon Website | Pokemon.com')
})

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init(defaultI18NextOptions)

export default i18n
