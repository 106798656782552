import { useEffect, useRef } from 'react'
import { useLocation, useParams, withRouter } from 'react-router-dom'

import { kickoffLogin } from './OAuthUtil'
import { useTranslation } from 'react-i18next'
import { Spinner } from '@pokemon/design.ui.loading-spinner'
import { AppBox } from './AppBox'

export const Landing = () => {
  const { childguid } = useParams()
  const location = useLocation()
  const { t } = useTranslation()

  const login = () => {
    window.sessionStorage.clear()
    const queryString = new URLSearchParams(location.search)

    if (queryString.get('code')) {
      window.sessionStorage.setItem('given_otc', queryString.get('code'))
    } else {
      throw new Error('missing code')
    }

    if (queryString.get('lng')) {
      window.sessionStorage.setItem('lng', queryString.get('lng'))
    }

    if (childguid) {
      window.sessionStorage.setItem('childguid', childguid)
    }

    kickoffLogin()
  }

  const needLogin = useRef(true)
  useEffect(() => {
    if (needLogin.current) {
      login()
      needLogin.current = false
    }
  })

  // This component will always redirect to another page
  return (
    <AppBox>
      <Spinner altText={t('pokemon_spinner', 'Loading')} />
    </AppBox>
  )
}

export default withRouter(Landing)
