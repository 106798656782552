import {
  Switch,
  Route
} from 'react-router-dom'
import { createContext, Suspense, useRef, useState, useCallback, useContext } from 'react'

import './App.css'
import EmailSent from './email_sent'
import Landing from './Landing'
import { Callback } from './Callback'
import ErrorBoundary from './ErrorBoundary'
import ShowError from './ShowError'
import { ConfirmDelete } from './confirm_delete'
import { SuccessfulDelete } from './SuccessfulDelete'
import AllApps from './AllApps'
import { getConsentedClients, getConsentedClientsChild } from './requests'
import { LoadingContext, Spinner } from '@pokemon/design.ui.loading-spinner'

export const AppContext = createContext()

export const App = () => {
  const [hasError, setHasError] = useState(false)
  const [errorText, setErrorText] = useState(undefined)
  const { setLoading } = useContext(LoadingContext)
  const childguid = window.sessionStorage.getItem('childguid')

  const handleError = useCallback((error, errorText) => {
    if (error) console.error(error)
    setHasError(true)
    setErrorText(errorText)
    setLoading(false)
  }, [])

  const handlePromise = useCallback((promise) => {
    promise.catch(error => handleError(error))
  }, [handleError])

  const context = useRef({ handleError, handlePromise })

  const [clients, setClients] = useState(null)
  const loadClients = useCallback(() => {
    const doLoad = async () => {
      setLoading(true)
      try {
        let response, loadedClients
        if (childguid) {
          response = await getConsentedClientsChild(window.sessionStorage.getItem('oauth_access_token'), childguid)
          loadedClients = response.data
        } else {
          loadedClients = await getConsentedClients(window.sessionStorage.getItem('oauth_access_token'))
        }
        setClients(loadedClients)
      } finally {
        setLoading(false)
      }
    }
    handlePromise(doLoad())
  }, [handlePromise, setClients, setLoading])

  return (
    <AppContext.Provider value={context.current}>
      <Suspense fallback={<Spinner altText='_' />}>
        <ErrorBoundary hasError={hasError} fallback={<ShowError errorText={errorText} />}>
          <Switch>
            <Route path='/email-sent'>
              <EmailSent />
            </Route>
            <Route path='/callback'>
              <Callback />
            </Route>
            <Route path='/confirm'>
              <ConfirmDelete clients={clients} loadClients={loadClients} childguid={childguid}/>
            </Route>
            <Route path='/all-apps'>
              <AllApps clients={clients} loadClients={loadClients} childguid={childguid}/>
            </Route>
            <Route path='/success' >
              <SuccessfulDelete childguid={childguid}/>
            </Route>
            <Route path='/:childguid'>
              <Landing />
            </Route>
            <Route path='/'>
              <Landing />
            </Route>
          </Switch>
        </ErrorBoundary>
      </Suspense>
    </AppContext.Provider>
  )
}

export default App
