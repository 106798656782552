import cryptoBrowserify from 'crypto-browserify'
import config from './config'

const base64URLEncode = str => {
  return str.toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

const sha256 = buffer => {
  return cryptoBrowserify.createHash('sha256').update(buffer).digest()
}

export const buildPokemonAuthUrl = (providerUrl, state, clientId, challenge, redirectUri) => {
  return `${providerUrl}/oauth2/auth?state=${state}&scope=${config.OAUTH_SCOPES}&response_type=code&client_id=${clientId}&code_challenge=${challenge}&code_challenge_method=S256&prompt=login&redirect_uri=${redirectUri}`
}

export const kickoffLogin = () => {
  const verifier = base64URLEncode(cryptoBrowserify.randomBytes(32))
  const challenge = base64URLEncode(sha256(verifier))

  const providerUrl = config.OAUTH_PROVIDER_URL
  const clientId = config.OAUTH_CLIENT_ID
  const redirectUri = encodeURI(config.OAUTH_CALLBACK_URL)
  const tokenUri = `${providerUrl}/oauth2/token`
  const state = base64URLEncode(cryptoBrowserify.randomBytes(16))

  const authUrl = buildPokemonAuthUrl(providerUrl, state, clientId, challenge, redirectUri)

  window.sessionStorage.setItem('pkce_verifier', verifier)
  window.sessionStorage.setItem('pkce_token_uri', tokenUri)
  window.sessionStorage.setItem('pkce_state', state)
  window.sessionStorage.setItem('pkce_client_id', clientId)
  window.sessionStorage.setItem('pkce_redirect_uri', redirectUri)

  window.location.assign(authUrl)
}

export const fetchAccessTokenFromCallback = async (queryString) => {
  const code = queryString.get('code')
  const state = queryString.get('state')
  if (!state || state !== window.sessionStorage.getItem('pkce_state')) {
    throw new Error('invalid state')
  }

  const verifier = window.sessionStorage.getItem('pkce_verifier')
  const tokenUri = window.sessionStorage.getItem('pkce_token_uri')
  const redirectUri = window.sessionStorage.getItem('pkce_redirect_uri')
  const clientId = window.sessionStorage.getItem('pkce_client_id')

  if (!verifier || !tokenUri || !redirectUri || !clientId) {
    throw new Error('missing pkce parameter in session')
  }

  const body = `code=${code}&grant_type=authorization_code&client_id=${clientId}&code_verifier=${verifier}&redirect_uri=${redirectUri}`
  const response = await fetch(tokenUri, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body
  })
  if (!response.ok) {
    throw Error('token request failed')
  }
  const responseJson = await response.json()
  return responseJson.access_token
}
