import React from 'react'

import { useTranslation } from 'react-i18next'
import { AppBox } from './AppBox'

function EmailSent () {
  const { t } = useTranslation()
  return (
    <AppBox>
      <div className='title-wrapper'>
        <h1>
          {t('landing_screen_header', 'Check Your Email')}
        </h1>
        <p className='sub-title'>
          {t('email_sent', 'An email has been sent to the address associated to this account containing a link to delete your account.')}
        </p>
      </div>
    </AppBox>
  )
}

export default EmailSent
